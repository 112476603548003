<!-- 批量导入物流 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('导入资料')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
        <el-button size="small" icon="el-icon-upload2" @click="doSelectFile()" type="primary">{{msg('选择文件')}}</el-button>
        <div ref="fileArea" style="display:none;">
        </div>
    </el-col>
    <el-col :span="12">
      <el-select v-model="molde" @change="selectChanged" placeholder="请选择模板" size="small">
        <el-option
          v-for="item in moldes"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="12">
        <el-button type="primary" size="mini" @click="doDownloadTpl" plain>
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('下载退件基础模板')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="downloadInnerTpl" v-if="hasPermission('store:downloadInnerTpl')" plain>
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('下载资料员模板')}}</span>
          </transition>
        </el-button>
    </el-col>
  </el-row>
  <div style="display: flex; flex-direction: row">
    <div class="table-wrap" style="width:50%">
      <el-form>
        <el-table :data="tableNames" style="width: 100%" size="mini" ref="dataTable" stripe>
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="name" :label="msg('数据列')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="tableName" min-width="120" :label="msg('选择表头')" >
            <template slot-scope="scope">
                  <el-form-item label-width="120" :prop="'tableNames.' + scope.$index + '.tableName'" >
                      <el-select v-model="scope.row.tableName" placeholder="请选择表头" size="small">
                          <el-option
                          v-for="item in tableHead"
                          :key="item.index"
                          :label="item"
                          :value="item">
                          </el-option>
                      </el-select>
                  </el-form-item>
              </template>
          </el-table-column>
          <el-table-column min-width="70" :label="msg('选择表头')" >
            <template slot="header">
              <el-button size="small" icon="el-icon-upload2" @click="doUpload()" type="primary">{{msg('确认上传')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div class="table-wrap" style="margin-left:10px;width:50%">
      <el-table :data="results" style="width: 100%" size="mini" ref="dataTable" stripe>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="orderCode" label="#" min-width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope"> 
            <span>
              {{scope.row.code || scope.row.orderCode || scope.row.expCode || scope.row.pageCode}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="result" :label="msg('上传结果')" min-width="200"></el-table-column>
      </el-table>
    </div>
  </div>
  <el-row :gutter="10" type="flex" justify="start" align="center" class="ctrl-wrap">
    <el-col :span="24">
        <el-alert title="首先选择文件，然后选择对应的模板，如没有模板则自行匹配对应的列明，匹配完成后点击确认上传"  type="warning"></el-alert>
    </el-col>
  </el-row>
</div>
</template> 

<script>
// @ is an alias to /src
import XLSX from "xlsx";
import auth from '../../../plugins/auth';

export default {
  name: 'StoreImport',
  components: {},
  mounted:function(){
    var _this = this;
  },
  data(){
    return{
      results:[],
      tableNames:[
        {name:'订单编号',tableName:null},
        {name:'面单码',tableName:null},
        {name:'查询码',tableName:null},
        {name:'品名',tableName:null},
        {name:'规格',tableName:null},
        {name:'件数',tableName:null},
        {name:'价格',tableName:null},
        {name:'上架码',tableName:null},
        {name:'货架号',tableName:null},
        {name:'客户账号',tableName:null},
      ],
      moldes:[
        {
          label: '基础模板',
          value: 0
        },
        {
          label: '虾皮模板',
          value: 1
        },
      ],
      molde:null,
      tableHead:[],
      tableJson:null,
      importModel:[
        ["订单编号","面单码","查询码","*品名","规格","*件数","配送方式"],
        ["訂單編號 (單)","","包裹查詢號碼 (單)","商品名稱 (品)","商品選項名稱 (品)","數量","寄送方式 (單)"],
      ]
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    },
    hasOther:{
      get() {
        return auth.methods.hasPermission('store:downloadInnerTpl');
      }
    }
  },
  methods: {
    initForm(){
        this.results.splice(0);
        this.tableHead = [];
        this.tableJson = null;
        this.tableNames=[
        {name:'订单编号',tableName:null},
        {name:'面单码',tableName:null},
        {name:'查询码',tableName:null},
        {name:'品名',tableName:null},
        {name:'规格',tableName:null},
        {name:'件数',tableName:null},
        {name:'配送方式',tableName:null},
        ];
      this.moldes=[
        {
          label: '基础模板',
          value: 0
        },
        {
          label: '虾皮模板',
          value: 1
        },
      ];
      this.importModel=[
        ["订单编号","面单码","查询码","*品名","规格","*件数","配送方式"],
        ["訂單編號 (單)","","包裹查詢號碼 (單)","商品名稱 (品)","商品選項名稱 (品)","數量","寄送方式 (單)"],
      ];
      this.molde = null;
      if(this.hasOther){
          this.tableNames.push(
            {name:'上架码',tableName:null},
            {name:'货架号',tableName:null},
            {name:'客户账号',tableName:null},
          )
          this.moldes.push(
            {
              label: '资料员模板',
              value: 2
            },
          )
          this.importModel.push(
              ["订单编号","面单码","查询码","品名","","","","上架码","货架号","客户号"],
          )
      }
    },
    downloadTpl(){
      window.location.href= this.$kit.api.expShelfBackDownload;
    },
    doSelectFile(){
      this.$refs.fileArea.innerHTML = "";
      var input = document.createElement("input");
      input.type="file";
      var _this = this;
      input.onchange=this.getUploadFile;    
      // input.onchange=function(e){
      //   _this.getUploadFile(e);
      // };    
      this.$refs.fileArea.appendChild(input);
      input.click();
    },
    selectChanged(value){
      //给表格赋值
      if(this.tableNames && this.importModel && this.importModel[value]){
        for(var i=0;i<this.tableNames.length;i++){
          this.tableNames[i].tableName = this.importModel[value][i];
        }
      }
    },
    getUploadFile(e) {
      var _this = this;
      _this.tableJson = null
        //拿到所导入文件的名字
        let fileName = e.target.files[0];
        //定义reader，存放文件读取方法
        let reader = new FileReader();
        //启动函数
        reader.readAsBinaryString(fileName)
        //onload在文件被读取时自动触发
        reader.onload = function(e) {

            //workbook存放excel的所有基本信息
            let workbook = XLSX.read(e.target.result, {type: 'binary'})
            //定义sheetList中存放excel表格的sheet表，就是最下方的tab
            let sheetList = workbook.SheetNames
            //存放json数组格式的表格数据
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let json = XLSX.utils.sheet_to_json(worksheet)
            _this.tableJson = json;
            var heads = [];
            //遍历json,得到表头tableHead[]
            for(var i=0;i<json.length;i++){
              var head = json[i];
                for(var key in head){
                  heads.push(key);
                }
            }

            _this.tableHead = [];
            for(var t=0;t<heads.length;t++){
                if(_this.tableHead.indexOf(heads[t]) < 0){
                     _this.tableHead.push(heads[t]);
                }
            }
        };
      },
    doUpload(){
        var _this = this;

        if(!this.tableNames[0].tableName && !this.tableNames[1].tableName && !this.tableNames[2].tableName){
          this.$message(this.msg('订单编号、面单码、物流码必须选择一个'));
          return;
        }
        if(!this.tableNames[3].tableName && !this.tableNames[5].tableName){
          this.$message(this.msg('品名、件数不能为空'));
          return;
        }

        var codeKey = this.tableNames[0].tableName;
        var pageCodeKey = this.tableNames[1].tableName;
        var expCodeKey = this.tableNames[2].tableName;
        var goodNameKey = this.tableNames[3].tableName;
        var specKey = this.tableNames[4].tableName;
        var qtyKey = this.tableNames[5].tableName;
        var expCompanyKey = this.tableNames[6].tableName;
        var upCode = null;
        var shelfCode = null;
        var username = null;
        if(this.hasOther){
          upCode = this.tableNames[7].tableName;
          shelfCode = this.tableNames[8].tableName;
          username = this.tableNames[9].tableName;
        }

        var targetData = [this.tableJson.length];

        if(!this.tableJson){
            this.$message(this.msg('请选择文件'));
            return;
        }else{
          for(var j=0;j<this.tableJson.length;j++){
            var json = this.tableJson[j];
              //console.log(json);
            var r = [
              json[codeKey], 
              json[pageCodeKey],
              json[expCodeKey],
              json[goodNameKey],
              json[specKey],
              json[qtyKey],
              json[expCompanyKey],
              json[upCode],
              json[shelfCode],
              json[username],
            ];
            targetData[j]=r;
          }
        }
        //console.log(targetData);
        //传到后台
        _this.axios.post(_this.$kit.api.twStore.updateExps, targetData).then(function (res) {
            if(res.status){
                var datas = res.data;
                datas.forEach(a => _this.results.push(a));
            }else{
              _this.$message(res.msg || _this.msg("上传失败"));
            }
        })

    },
    doDownloadTpl(){
      window.location.href= this.$kit.api.storeBackDownload;
    },
    downloadInnerTpl(){
      window.location.href= this.$kit.api.storeBackDownloadInner;
    }
  }
}
</script>

<style class="scss" scoped>
.el-table--mini {
    font-size: 14px;
}
.el-form-item {
    margin-bottom: 0px;
}
</style>
