<!-- 批量导入仓位号 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('导入仓位号')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
        <el-button size="small" icon="el-icon-upload2" @click="doSelectFile()" type="primary">{{msg('导入仓位号')}}</el-button>
        <div ref="fileArea" style="display:none;">

        </div>
    </el-col>
    <el-col :span="12" style="text-align:right;">
        <el-button type="success" icon="el-icon-download" @click="downloadTpl()" size="small">{{msg('模板下载')}}</el-button>
    </el-col>
  </el-row>

  <div class="table-wrap">
    <el-table :data="results" style="width: 100%" height="400px" size="mini" ref="dataTable" stripe>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="code" label="上架码" min-width="120" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="storeCode" min-width="120" label="仓位号" ></el-table-column>
      <el-table-column prop="result" :label="msg('上传结果')" min-width="200"></el-table-column>
    </el-table>
  </div>
  <el-row :gutter="10" type="flex" justify="start" align="center" class="ctrl-wrap">
    <el-col :span="24">
        <el-alert :title="msg('仓位号title')"  type="warning"></el-alert>
    </el-col>
  </el-row>
</div>
</template> 

<script>
// @ is an alias to /src
import XLSX from "xlsx";

export default {
  name: 'StoreImport',
  components: {},
  mounted:function(){
    var _this = this;
  },
  data(){
    return{
      results: [],   //表格数据列表
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    initForm(){
        this.results.splice(0);
    },
    downloadTpl(){
      window.location.href= this.$kit.api.storeCodeDownload;
    },
    doSelectFile(){
      this.$refs.fileArea.innerHTML = "";
      var input = document.createElement("input");
      input.type="file";
      var _this = this;
      input.onchange=this.getUploadFile;    
      this.$refs.fileArea.appendChild(input);
      input.click();
    },
    getUploadFile(e) {
      var _this = this;
        //拿到所导入文件的名字
        let fileName = e.target.files[0];
        //定义reader，存放文件读取方法
        let reader = new FileReader();
        //启动函数
        reader.readAsBinaryString(fileName)
        //onload在文件被读取时自动触发
        reader.onload = function(e) {
            //workbook存放excel的所有基本信息
            let workbook = XLSX.read(e.target.result, {type: 'binary'})
            //定义sheetList中存放excel表格的sheet表，就是最下方的tab
            let sheetList = workbook.SheetNames
            //存放json数组格式的表格数据
            //存放字符串数组格式的表格数据
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let json = XLSX.utils.sheet_to_json(worksheet)
          
          var targetData = [json.length];
          for(var i=0;i<json.length;i++){
            var row = json[i];
            var code = row["上架码"];
            var storeCode = row["仓位号"];
            if(!code){
              _this.$message('第'+(i+1)+"行上架码为空");
              continue;
            }

            if(!storeCode){
              _this.$message('第'+(i+1)+"行仓位号为空");
              continue;
            }

            var r = [
              code, storeCode
            ];
            targetData[i]=r;
          }

          //传到后台
          _this.axios.post(_this.$kit.api.twStore.updatesStoreCode, targetData).then(function (res) {
              if(res.status){
                  var datas = res.data;
                  datas.forEach(a => _this.results.push(a));
              }else{
                _this.$message(res.msg || _this.msg("上传失败"));
              }
          })

        };


      },

      getObjValue(obj, idx){
        let i=0;
        for(var key in obj){
          if(idx == i){
            return obj[key];
          }else{
            i++;
          }

        }
      }

  }
}
</script>

<style class="scss" scoped>


</style>
