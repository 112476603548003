<template>
<el-form ref="form" :model="store" label-width="100px">
    <h3>仓库信息</h3>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('订单编号')">
                <el-input v-model="store.orderCode" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('货架号')">
                <el-input v-model="store.shelfCode" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('物流状态')">
                <el-select v-model="store.status" :disabled="true">
                    <el-option v-for="(d, index) in dicts.storeStatus" :key="index" :label="d" :value="index"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('过期时间')">
                <el-input v-model="store.saveExpire" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('品名')">
                    <el-input v-model="store.goodName" type="text" :disabled="true"></el-input>
                </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('规格')">
                    <el-input v-model="store.spec" type="text" :disabled="true"></el-input>
                </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('件数')">
                    <el-input v-model="store.qty" type="text" :disabled="true"></el-input>
                </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('面单码')">
                <el-input v-model="store.pageCode" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('台湾物流码')">
                <el-input v-model="store.expCode" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    
    <h3>订单信息</h3>
    <el-table :data="store.details" style="width: 100%; padding-right:20px;"
            size="mini" ref="dataTable" stripe>
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="id" label="ID" min-width="50" ></el-table-column>
        <el-table-column label="商品名" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.name" size="mini" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="规格" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.spec" size="mini" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="件数" min-width="80">
            <template slot-scope="scope">
                <el-input v-model="scope.row.qty" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="价格" min-width="120">
            <template slot-scope="scope">
                <el-input v-model="scope.row.price" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="备注" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.remark" size="mini"></el-input>
            </template>
        </el-table-column>
    </el-table>
    
    <div style="text-align:center; margin-top:20px;">
        <!-- <el-button type="primary" size="mini" @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button> -->
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('关闭')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpEdit',
  data(){
    return{
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      },
  },
  props:{
      id:null,
      store:Object,
  },
  mounted: function(){
    var _this = this;
    var user = JSON.parse(window.localStorage.getItem("user"));
    this.userName = user.username;
  },
  methods:{
 
    onSubmit(){
       this.$emit("closeDg", true);
    },

  }
}
</script>

<style class="scss" scoped>


</style>
