<!--关联转寄页面-->
<template>
    <div class="content-wrap">
        <div >
            <span>{{msg('货单号：')}}</span>
            <el-select v-model="codes" style="width: calc(100% - 150px);"
                multiple filterable allow-create  default-first-option
                :placeholder="msg('请输入单号')">
                   
            </el-select>
            <el-button type="success" icon="el-icon-search" circle @click="getExps"></el-button>
        </div>
        
        <!-- <div v-for="store in stores" :key="store.id" > -->
        <div style="width:100%; margin:15px 0px;">
            <!-- <div style="margin: 15px 0px;">
                <span class="cell">
                    <span class="label">{{msg('订单编号')}}：</span><span class="txt">{{store.orderCode}}</span>
                </span>
                <span class="cell">
                    <span class="label">{{msg('品名')}}：</span><span class="txt">{{store.goodName}}</span>
                </span>
                <span class="cell">
                    <span class="label">{{msg('收件人')}}：</span><span class="txt">{{store.receiver}}</span>
                </span>
                <span class="cell">
                    <span class="label">{{msg('物流类型')}}：</span><span class="txt">{{store.expCompany}}</span>
                </span>
                <span class="cell">
                    <span class="label">{{msg('新物流')}}：</span>
                    <el-select placeholder="请选择" v-model="store.expLabel" style="width:300px;"
                            @change="(expLabel) => selectExp(expLabel, store)">
                        <el-option v-for="exp in exps" :key="exp.id" 
                            :label="exp.id+'-'+exp.orderCode+'-'+exp.goodName" 
                            :value="exp.id+'-'+exp.orderCode+'-'+exp.goodName"
                        ></el-option>
                    </el-select>
                </span>
            </div> -->

            <el-table :data="details" stripe border style="width:100%; ">
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="orderCode" :label="msg('货单号')" width="150"></el-table-column>
                <el-table-column prop="name" :label="msg('品名')" ></el-table-column>
                <el-table-column prop="price" :label="msg('价格')" width="80"></el-table-column>
                <el-table-column prop="spec" :label="msg('规格')"></el-table-column>
                <el-table-column prop="qty" :label="msg('数量')" width="80"></el-table-column>
                <el-table-column :label="msg('订单')" >
                    <template slot-scope="scope">
                        <el-select placeholder="请选择" v-if="exps" v-model="scope.row.orderLabel"
                            @change="(key) => selectOrder(key, scope.row)" >
                                <el-option v-for="exp in exps"  :key="exp.id" 
                                    :value="exp.orderCode+'/'+exp.name +'/' + exp.qty + '(件)' + '/' + exp.spec+'-'+exp.orderId " 
                                    :label="exp.orderCode+'/'+exp.name +'/' + exp.qty + '(件)' + '/' + exp.spec+'-'+exp.orderId " >
                                </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('图片')" width="70">
                    <template slot-scope="scope">
                        <el-upload
                            class="avatar-uploader"
                            :action="upload"
                            :with-credentials="true"
                            :show-file-list="false"
                            :on-success="(res, file) => handleAvatarSuccess(res, file, scope.row)">
                                <img v-if="scope.row.pic" :src="scope.row.pic" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('备注')">
                     <template slot-scope="scope">
                        <el-input type="textarea" :placeholder="msg('请输入备注')" 
                            v-model="scope.row.remark" :rows="3">
                        </el-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
       
        <div style="text-align:center;padding-top: 20px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
        </div>
    </div>
   
</template>

<script>
export default {
  name: 'Resend',
  data(){
    return{
      inlineForm: {},
      codes: null,      //新物流的订单编号，列表
      upload: this.$kit.api.upload,
      imgView: this.$kit.api.imgView,
      stores: [],   //待转寄的仓库数据
      exps: [],
      details: [],
    }
  }, 
  mounted: function(){
  },
  methods:{
    selectOrder(label, storeDetail){  //选中物流信息
        let orderId = label.split("-")[1];

        var order = this.exps.find(e => e.orderId == orderId);
        if(order){
            order["storeDetail"] = storeDetail;
        }
    },
    // selectExp(label, store){
    //     let expId = label.split("-")[0];

    //     var exp = this.exps.find(e => e.id == expId);
    //     if(exp){
    //         store.exp = exp;
    //     }

    // },
    getExps(){ //获取物流数据
        if(this.codes.length == 0){
            this.$message(this.msg("订单编号为空！"));
            return;
        }
        
        //获取数据
        this.$axios.get(this.$kit.api.twStore.resendExps+"?cs="+this.codes.join(','))
            .then((result) => {
                if(result.status){
                    if( result.data && result.data.length>0){
                        // var datas = result.data;
                        // for(var i=0; i<datas.length; i++){
                        //     var data = datas[i];
                        // }
                        this.exps = result.data
                    }else{
                        this.$message(this.msg('无可用库存'));
                    }
                }else{
                    this.$message(result.msg || this.msg("获取失败"));
                }
            });
    },
    getStores(ids){  //获取库存数据
        //获取库存数据 
        //处理图片链接

        //获取数据
        this.$axios.get(this.$kit.api.twStore.details+"?ids="+ids.join(','))
            .then((result) => {
                if(result.status){
                    if( result.data && result.data.length>0){
                        this.details = [];
                        var datas = result.data;
                        for(var i=0; i<datas.length; i++){
                            var store = datas[i];
                            if(store.status == 0){
                                // store['exp'] = null;
                                // store["expLabel"] = null;
                               
                                //判断库存中是否还有数量
                                //只保留有库存的数据
                                store.details.forEach(detail => {
                                    if(detail.qty > 0){
                                        detail["orderLabel"] = null;
                                        if(detail.productPicId){  //初始化图片路径
                                            detail['pic'] =  this.imgView + detail.productPicId;
                                        }else{
                                            detail['pic'] = null;
                                        }
                                        
                                        detail["expId"] = store["expId"];
                                        detail["storeId"] = store["id"];
                                        detail["shelfCode"] = store["shelfCode"];
                                        detail["status"] = store["status"];
                                        detail["orderCode"] = store["orderCode"];
                                        
                                        this.details.push(detail);
                                    }
                                });
                            }
                        }
                        
                    }else{
                        this.$message(this.msg('无可用库存'));
                    }
                }else{
                    this.$message(result.msg || this.msg("获取失败"));
                }
            });
    },
    handleAvatarSuccess(res, file, storeDetail) { //上传成功
        this.$message(res.msg);
        if(res.status){
            storeDetail.pic = this.$kit.api.imgView+res.data;
            storeDetail.productPicId = res.data;
        }
    },
    onSubmit(){
        
        var resend = [];
        for(var i=0; i<this.exps.length; i++){

            var exp = this.exps[i];
            var detail = exp['storeDetail'];
            if(detail){
                resend.push({
                    storeDetailId: detail.id,
                    orderId: exp.orderId,
                    remark: detail.remark,
                    picId: detail.productPicId,
                    expId: exp.id,
                    storeId: detail.storeId,
                });
            }
            

            // var orders = exp.orders;
            // for(var j=0; j<orders.length; j++){
            //     var order = orders[j];

            //     var detail = order['storeDetail'];

            //     if(detail){
            //         resend.push({
            //             storeDetailId: detail.id,
            //             orderId: order.id,
            //             remark: detail.remark,
            //             picId: detail.productPicId,
            //             expId: order.expId,
            //             storeId: detail.storeId,
            //         });
            //     }
            // }

        }


        if(resend.length == 0){
            this.$message(this.msg('请至少提交一条数据'));
        }

         //获取数据
        this.$axios.post(this.$kit.api.twStore.resend, resend)
            .then((result) => {
                this.$message(result.msg || this.msg("获取失败"));
            });

    }


  }
}
</script>

<style class="scss" scoped>
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  .avatar {
    width: 60px;
    height: 60px;
    display: block;
  }
  .cell{display: inline-block; margin-left:5px; margin-right:10px;}
  .cell .label{display: inline-block; margin-right:5px; }
  .cell .txt{display: inline-block;  font-weight: bold; color: rgb(102, 177, 255);}
</style>
