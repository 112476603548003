<!--打印寄回大陆的 回寄单和找货单 的打印模板-->
<template>
<div>
<vue-easy-print tableShow ref="easyPrint">
    <template>
        <div class="print-body">
            <div class="custs" v-if="data">
                <div class="cust" v-for="(cust, idx) in data.customerBackPrints" :key="idx" 
                        style="page-break-before: always;">
                    <div class="date">{{cust.printDate}}</div>
                    <div class="area1">
                        <img :src="'data:image/png;base64,'+cust.orderCodeAsBarCode"/>
                        <div>{{cust.orderCode}}</div>
                    </div>

                     <div class="area2">
                        <p>{{cust.ownerName}}</p>
                        <img :src="'data:image/png;base64,'+cust.backCodeAsBarCode"/>
                        <div>{{cust.backCode}}</div>
                    </div>

                    <div class="total">總票數：{{cust.total}}票</div>
                    <div class="tb1-wrap">
                        <table class="tb1">
                            <thead>
                                <tr class="th1">
                                    <th class="th11">貨單號</th>
                                    <th class="th12">面單碼</th>
                                    <th class="th13">配送方式</th>
                                    <th class="th14">架位號</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="td1" v-for="(shelf, idx) in cust.shelfs" :key="idx">
                                    <td class="td11"><span>{{shelf.orderCode}}</span></td>
                                    <td class="td12"><span>{{shelf.expPageCode}}</span></td>
                                    <td class="td13">{{shelf.expCompany}}</td>
                                    <td class="td14">{{shelf.shelfCode}}</td>
                                </tr>
                            </tbody> 
                        </table>
                    </div>
                </div>
            </div><!--end custs-->
            <div style="page-break-before: always;"></div>
            <div class="shelfs">
                <div class="tb2-wrap" style="margin:15px 0px;">
                    <table class="tb2" style="position:relative; left:-18px;">
                        <thead>
                            <tr class="th2">
                                <th class="th21">貨單號</th>
                                <th class="th22">面單碼</th>
                                <th class="th23">配送方式</th>
                                <th class="th24">收件人</th>
                                <th class="th25">架位號</th>
                                <th class="th26">重量</th>
                                <th class="th27">客戶名</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="td2" v-for="(shelf, idx) in data.shelfBackPrintDto" :key="idx">
                                <td class="td21">{{shelf.orderCode}}</td>
                                <td class="td22">{{shelf.expPageCode}}</td>
                                <td class="td23">{{shelf.expCompany}}</td>
                                <td class="td24">{{shelf.receiver}}</td>
                                <td class="td25">{{shelf.shelfCode}}</td>
                                <td class="td26">{{shelf.weight}}</td> 
                                <td class="td27">{{shelf.fullname}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div><!--end shelfs-->
        </div>
    </template>
</vue-easy-print>
</div>
 
</template>

<script>
import vueEasyPrint from "vue-easy-print";

export default {
  name: 'ExpBackPrintTicket',
  components: {
    vueEasyPrint:vueEasyPrint
  },
  data(){
    return{
       data: null
    }
  }, 
 
  props:{},
  mounted: function(){
      
  },
  methods:{
    initForm(data){
        this.data = data;
        //打开打印界面
        setTimeout(()=>{
            this.$refs.easyPrint.print();
        }, 1000);
    }
  }
}
</script>

<style class="scss" scoped>
.print-body{
  text-align:center; 
}

.cust{
  margin:0px auto; padding:10px;  text-align:left;width: 350px; 
}

.print-body .date{
  font-size:20px;  margin-bottom:5px;
}
.area1{text-align:center; border-bottom: 1px solid #000000; padding-bottom: 15px;}
.area2{
  text-align:center; border-bottom: dashed 2px black; padding-bottom:15px;
}
.area2 p{margin:0px; padding-top:10px; padding-bottom:5px; font-size:24px; font-weight: bold;
  
}
.cust .total{font-size:18px;  margin-top:10px; margin-bottom:10px;}
.print-body table{width:100%;}
.print-body table th{font-size:12px; text-align:center;   
}
.print-body table thead th{border-right: 1px solid #8a8a8a; text-align:center;}
.print-body table{border-top: 1px solid #8a8a8a; border-left: 1px solid #8a8a8a; 
  border-collapse:collapse; border-spacing: 0px; 
} 
.print-body table tbody td{
  border-top: 1px solid #8a8a8a; 
  border-bottom: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
  font-size:12px;
}

.th11, .th12{width: 110px;} 
.th21, .th22{width: 60px;}
.th14{width:60px;}
.td11 span, .td12 span{display:inline-block;width:110px; white-space: nowrap; overflow:hidden;}
.td13, .td14{text-align:center;}
.td23, .td24, .td25, .td26, .td27{text-align:center;}

.th21, .th22, .th23, .th24, .th25, .th26, .th27{white-space:nowrap}   

.shelfs{text-align:center; width:350px; margin:0px auto; }



</style>
