<!--台湾仓库列表页面-->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('仓储管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('物流编号')" v-model="query.orderCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('货架号')" v-model="query.shelfCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select class="query-select" size="small" 
          v-model="ownerName" multiple filterable
          allow-create default-first-option
          placeholder="归属人">
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('回寄单号')" v-model="query.backCode" size="small">
          <i slot="prefix" class="el-input__icon  el-icon-s-goods"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店铺')" v-model="query.shopName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-office-building"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4" clearable >
        <el-select v-model="query.expCompany" size="small" placeholder="物流厂商" class="query-select" clearable>
          <el-option v-for="d in dicts.expCompanies" :key="d.value" :label="d.label" :value="d.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.status" size="small" placeholder="仓库状态" class="query-select" clearable>
        <el-option v-for="(status, idx) in dicts.storeStatus" :label="status" :value="idx" :key="idx"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('面单码')" v-model="query.pageCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('查询码')" v-model="query.expCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('上架码')" v-model="query.code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('创建人')" v-model="query.creater" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-select v-model="query.pageType" class="query-select" placeholder="订单来源" size="small" clearable>
          <el-option v-for="(d, idx) in dicts.expOrigins1" :key="idx" :label="d.label" :value="d.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-select v-model="query.hasStorePkg" class="query-select" placeholder="打包类型" size="small" clearable>
          <el-option v-for="(d, idx) in dicts.expPkgTypes1" :key="idx" :label="d.label" :value="d.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('上架开始日期')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('上架结束日期')"
            size="small"
          >
        </el-date-picker>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar1" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()" v-if="hasPermission('store:upper')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('入仓')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="edit()" v-if="hasPermission('store:view')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('查看')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" @click="printBackTicket" size="mini" v-if="hasPermission('store:printTicket')">
          <i class="el-icon-printer" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('打印回寄单')}}</span>
          </transition>
        </el-button>
        <el-dropdown @command="clickImportItem" v-if="hasPermissions(['store:uppers', 'store:importBacks'])">
          <el-button type="primary" size="mini" icon="el-icon-upload">
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('导入')}}</span>
            </transition>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="import" v-if="hasPermission('store:uppers')">{{msg('导入货架号')}}</el-dropdown-item>
            <el-dropdown-item command="importStore" v-if="hasPermission('store:importBacks')">{{msg('导入退件信息')}}</el-dropdown-item>
            <el-dropdown-item command="importStoreCode" v-if="hasPermission('store:importStoreCode')">{{msg('导入仓位号')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="clickSolveItem" v-if="hasPermissions(['store:normal','store:resend','store:cancel', 'store:addDay', 'store:sendBack'])">
          <el-button type="primary" size="mini" icon="el-icon-upload">
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('处理货物')}}</span>
            </transition>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="resend" v-if="hasPermission('store:resend')">{{msg('重出转寄')}}</el-dropdown-item>
            <el-dropdown-item command="add-day" v-if="hasPermission('store:addDay')">{{msg('保管延期')}}</el-dropdown-item>
            <el-dropdown-item command="send-back" v-if="hasPermission('store:sendBack')">{{msg('寄回大陆')}}</el-dropdown-item>
            <el-dropdown-item command="cancel" v-if="hasPermission('store:cancel')">{{msg('弃件')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('store:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getStores(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="success" @click="queryVisiable = true;" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('批量查询')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('仓储信息下载'),sheetName: 'Sheet1' })" >
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  
  <div class="table-wrap">
     <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="stores" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox"  min-width="55" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <!-- 上架时间、货单号、货架号、品名、规格、件数、面单码、物流码、退件状态、店铺名、保管期限、回寄单号、归属人、操作人、图片、备注 -->
      <vxe-table-column field="id" :title="msg('ID')" min-width="90" :fixed="isMinScreen?null: 'left'"
         sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <vxe-table-column field="created"  :title="msg('入仓时间')" min-width="120"
        sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <vxe-table-column field="orderCode"  :title="msg('货单号')" min-width="150" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="code" :title="msg('上架码')" min-width="150" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="shelfCode" :title="msg('货架号')" min-width="120"
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <vxe-table-column field="storeCode" :title="msg('仓位号')" min-width="120"
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <vxe-table-column field="goodName" :title="msg('品名')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="spec" :title="msg('规格')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="qty" :title="msg('件数')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="status" :title="msg('仓库状态')" min-width="130" 
        sortable :filters="dicts.storeStatus1"
      >
        <template v-slot="{ row }"> 
          <el-tag :type="dicts.cssTypes[row.status]" disable-transitions>
            {{dicts.storeStatus[row.status]}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="pageCode" :title="msg('面单码')" min-width="160" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="expCode" :title="msg('物流码')" min-width="150" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="saveExpire" :title="msg('保管期限')" min-width="120" 
        sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <vxe-table-column field="backCode"  :title="msg('回寄单号')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="ownerName"  :title="msg('归属人')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="createrName" :title="msg('创建人')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="shopOwner" :title="msg('店铺')" min-width="120" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <vxe-table-column field="expCompany" :title="msg('物流厂商')" min-width="120" 
        sortable :filters="dicts.expCompanies"
      ></vxe-table-column>
      <vxe-table-column field="hasStorePkg" :title="msg('打包类型')" min-width="150" 
        sortable prop="hasStorePkg" :filters="dicts.expPkgTypes1" 
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.hasStorePkg]" disable-transitions>
            {{dicts.expPkgTypes[row.hasStorePkg]}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="pageType" :title="msg('订单来源')" min-width="130" 
        sortable :filters="dicts.expOrigins1"
      >
        <template v-slot="{ row }">
          <span>
            <el-tag :type="dicts.cssTypes[row.pageType]" disable-transitions>
              {{dicts.expOrigins[row.pageType]}}
            </el-tag>
          </span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="remark" :title="msg('备注')" min-width="120"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" @opened="handleShelfOpen" width="400px">
    <store-code-edit :id="currId" :shelf="currShelf" v-on:closeDg="closeEditDialog" ref="shelfEdit"/>
  </el-dialog>

  <el-dialog :title="msg('导入上架号')" :visible.sync="dialogImportVisible" :fullscreen="isMinScreen"  width="600px" @opened="hendleOpenedImport()" 
      @closed="getStores" >
    <store-import-code ref="StoreImportRef"/>
  </el-dialog> 

  <el-dialog :title="msg('导入仓位号')" :visible.sync="dialogImportVisibleCode" :fullscreen="isMinScreen"  width="600px" @opened="hendleOpenedImportCode()" 
      @closed="getStores" >
    <store-import-scode ref="StoreImportCodeRef"/>
  </el-dialog> 

  <el-dialog :title="msg('导入退件资料')" :visible.sync="dialogImportVisibleTpl" :fullscreen="true"  @opened="hendleOpenedImportTpl()" 
      @closed="getStores" >
    <store-import-back ref="StoreImportTplRef" v-on:closeDg="closeImportTplRef"/>
  </el-dialog>

  <el-dialog :title="msg('查看')" :visible.sync="dialogEdit" :fullscreen="isMinScreen" width="800px">
    <store-edit :id="storeId" :store="currStore" v-on:closeDg="closeStoreEditDialog" />
  </el-dialog>

  <el-dialog :title="msg('重出转寄')" :visible.sync="addRsendDgVisiable" :fullscreen="true" width="800px" @opened="opendAddResendDg">
    <add-resend ref="addResendRef" v-on:closeDg="() => {this.getStores(); this.addRsendDgVisiable=false;}" />
  </el-dialog>

  <el-dialog :title="msg('打印回寄单')" :visible.sync="dialogPrintVisiable" :fullscreen="isMinScreen"  width="800px" @opened="handleOpenPrint()">
    <print-ticket-tpl ref="PrintRef"/>
  </el-dialog> 

  <el-dialog :title="msg('确定要将选中信息保管延期吗？')" :visible.sync="daysVisiable" :fullscreen="isMinScreen"  width="400px">
      <div style="text-align:center; ">
        <el-input-number style="margin-bottom:25px; " v-model="days" :min="1" :max="100" label="延期天数："></el-input-number>&nbsp;&nbsp;天
        <el-button type="primary" size="mini"  @click="doAddDays()"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="daysVisiable=false">{{msg('取消')}}</el-button>
      </div>
  </el-dialog> 

  <el-dialog :title="msg('批量查询')" :visible.sync="queryVisiable" :fullscreen="isMinScreen"  width="450px" >
    <store-query ref="queryForm" @closeDg="doBatchQuery"/>
  </el-dialog> 


</div>
</template>

<script>
// @ is an alias to /src
import StoreCodeEdit from "./StoreCodeEdit.vue";
import StoreImportCode from "./StoreImportCode.vue";
import StoreImportScode from "./StoreImportScode.vue";
import StoreImportBack from "./StoreImportBack.vue";
import StoreEdit from "./StoreEdit.vue";
import StoreQuery from "./StoreQuery.vue";

import AddResend from "./AddResend.vue";
import ExpBackPrintTicketTpl from "./ExpBackPrintTicketTpl.vue";

export default {
  name: 'store',
  components: {
    StoreCodeEdit:StoreCodeEdit,
    StoreImportCode:StoreImportCode,
    StoreImportScode:StoreImportScode,
    StoreEdit:StoreEdit,
    StoreImportBack:StoreImportBack,
    PrintTicketTpl: ExpBackPrintTicketTpl,
    AddResend:AddResend,
    StoreQuery:StoreQuery  
    },
  mounted:function(){
    this.getStores();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10, 
          Date:[],
          orderCode:null,
          shelfCode:null,
          pageCode:null,
          backCode: null,
        },
        ownerName: [],
        currentPage: 1,   //分页的当前页
        stores: [],   //表格数据列表
        dglogTitle: this.msg('编辑上架信息'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        currShelf:null,
        isAdd : false,
        dialogTableVisible:false,
        dialogImportVisible:false,
        dialogImportVisibleTpl:false,
        dialogImportVisibleCode:false,
        dialogEdit:false,
        storeId:null,
        currStore:null,

        dialogPrintVisiable: false,
        printData: null,

        daysVisiable: false,
        days: 0,

        addRsendDgVisiable: false, //重出转寄弹出窗

        queryVisiable: false, //查询弹窗
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
      get() {
        return this.$store.state.isMinScreen;
      }
    },
    docHeight: {  //表格的高度
      get() {
        var height =  this.$store.state.docHeight;
        return height - 320;
      }
    }
  },
  methods: {
    getStores: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      if(this.ownerName && this.ownerName.length>0){
        param.ownerName = this.ownerName;
      }
      if(start){
        param.start = 0;
        this.currentPage = 1;
        this.queryType = null;
        this.queryCodes = null;
      }

      this.axios.post(this.$kit.api.twStore.list, param).then(function (result) {
        if(result.status){
          if(_this.stores.length>0)
            _this.stores.splice(0, _this.stores.length);
          
          _this.total = result.data.recordsTotal;
          var datas = result.data.data;

          for(var i=0; i<datas.length; i++){
            var item = datas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }
            if(item.saveExpire){
                item.saveExpire = item.saveExpire.substring(0,10);
            }

            _this.stores.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.ownerName.splice(0,this.ownerName.length);
      this.getStores();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getStores();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getStores();
    },
    //批量查询
    doBatchQuery(data){
      this.queryVisiable = false;
      if(data){
        this.query['type'] = data.type;
        this.query['codes'] = data.codes;
        this.queryType = data.type;
        this.queryCodes = data.codes;
        this.getStores();
      }else{
        this.queryType = null;
        this.queryCodes = null;
      }
    },
    //导出
    download(){
      var _this = this;
      var param = this.$kit.rebuildQuery(this.query);
      window.location.href= this.$kit.api.expShelfTableDownload+"?data="+encodeURIComponent(JSON.stringify(param));
    },
    //入仓
    add(){
        this.isAdd = true;
        this.currId = null;
        this.currShelf = {
          shelfCode: null,
          expPageCode: null,
        };
        this.dglogTitle = this.msg("添加上架信息");
      
        this.dialogTableVisible = true;
    },
    handleShelfOpen(){
      this.$nextTick(() => {
        this.$refs.shelfEdit.initForm(this.currShelf.shelfCode, this.isAdd)
      });
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
        this.getStores();
      this.dialogTableVisible = false;
    },
    closeStoreEditDialog(){
        this.getStores();
        this.dialogEdit = false;
    },
    closeImportTplRef(){
      this.getStores();
      this.dialogImportVisibleTpl = true;
    },
    //编辑
    edit(){
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length>1){
        this.$message(this.msg('只能操作一条数据'));
        return;
      }

      this.axios.get(this.$kit.api.twStore.view+"?id="+selectes[0].id).then((result) => {
        if(result.status){
            _this.currStore = result.data;
            _this.storeId = _this.currStore.id;

            _this.dialogEdit = true;
        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
          console.error(error);
      });

    },
    //匹配数据
    link(){

    },
    //删除
    deleted(){
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
        }

        var ids = selectes.map( o => o.id);
        var _this = this;

        this.$confirm(this.msg('确定要删除该物流信息吗?'), this.msg('提示'), {
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
          }).then(() => {
            _this.$axios.post(_this.$kit.api.twStore.delete+"?ids="+ids)
              .then(function(result){
                _this.$message(result.msg || _this.msg('删除成功'));
                _this.getStores();
            });
          });
    },
    //导入
    clickImportItem(command){
      if(command=='import'){
        //导入货架号
        this.dialogImportVisible = true;
      }else if(command=='importStore'){
        //导入退件数据
        this.dialogImportVisibleTpl = true;
      }else if(command == 'importStoreCode'){
        //导入仓位号
        this.dialogImportVisibleCode = true;
      }
    },
    //处理货物clickSolveItem
    clickSolveItem(command){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      
      if(command=='normal'){
        //正常发货
      }else if(command=='resend'){
        //退件转寄
        this.processResend();
      }else if(command=='add-day'){
        //保管延期
        this.daysVisiable = true;
        this.days = 1;
      }else if(command=='send-back'){
        //寄回大陆
        this.doSendBack(selectes);
      }else if(command=='cancel'){
        //弃件
        this.doCancel(selectes);
      }
    },
    hendleOpenedImport(){
      this.$nextTick(() => {
        this.$refs.StoreImportRef.initForm();
      });
    },
    hendleOpenedImportCode(){
      this.$nextTick(() => {
        this.$refs.StoreImportCodeRef.initForm();
      });
    },
    hendleOpenedImportTpl(){
      this.$nextTick(() => {
        this.$refs.StoreImportTplRef.initForm();
      });
    },
    //退件转寄
    processResend(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      this.addRsendDgVisiable = true;
    },
    opendAddResendDg(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      this.$nextTick(() => {
        this.$refs.addResendRef.getStores(selectes.map(s => s.id));
      });
    },
     //寄回大陆
    doSendBack(selectes){
      this.$confirm(this.msg('确定要将选中的数据寄回大陆？'), '提示', {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          this.axios.get(this.$kit.api.twStore.sendBack
              +"?ids="+selectes.map(a => a.id).join(","))
            .then((result) => {
              this.$message(result.msg);
              if(result.status){
                this.getStores();
              }
            }).catch(function (error) { 
                console.error(error);
            });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    //保管延期
    doAddDays(){
      this.axios.get(
        this.$kit.api.twStore.addDay
          +"?ids="+this.$refs.dataTable.getCheckboxRecords().map(a => a.id).join(",")
          +"&day="+this.days)
        .then((result) => {
          this.$message(result.msg);
          if(result.status){
            this.getStores();
          }
        }).catch(function (error) {
          console.error(error);
        });
    },
    doCancel(selectes){  //弃件
      var _this = this;
       this.$prompt('请输入备注', this.msg('确定要丢弃该退件？'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
        }).then(({ value }) => {
          
          _this.axios.get(
            _this.$kit.api.twStore.cancel
              +"?ids="+selectes.map(a => a.id).join(",")
              +"&remark="+(value?encodeURIComponent(value):""))
          .then((result) => {
            _this.$message(result.msg);
            if(result.status){
              _this.getStores();
            }
          }).catch(function (error) {
              console.error(error);
          });
        }).catch(() => {
          console.log('取消输入');     
        });
      
    },
    printBackTicket(){
      var owners = null;
      if(this.ownerName && this.ownerName.length>0){
        owners = this.ownerName.join(",");
      }

      this.axios.get(
          this.$kit.api.twStore.printTicket
            +"?owner="+(owners?owners:'')
            +"&backCode="+(this.query.backCode?this.query.backCode : ""))
        .then((result) => {
          this.$message(result.msg);
          if(result.status){
            this.printData = result.data;
            this.dialogPrintVisiable = true;
          }
        }).catch(function (error) {
            console.error(error);
        });
    },

    handleOpenPrint(){
      this.$nextTick(() => {
        this.$refs.PrintRef.initForm(this.printData);
      });
    },
  }
}
</script>

<style class="scss" scoped>


</style>
