<!-- 添加退件上架信息 -->
<template>
<el-form ref="form" :model="shelf" label-width="100px">
    <el-form-item :label="msg('上架码')" v-if="!isAdd">
        <el-input v-model="shelf.expPageCode"></el-input>
    </el-form-item>
    <el-form-item :label="msg('上架码')" v-if="isAdd && !openAutoSave">
        <el-input v-model="pageCode"></el-input>
    </el-form-item>
    <el-form-item :label="msg('上架码')" v-if="isAdd && openAutoSave" @keyup.native="autoSave">
        <el-input v-model="pageCode"></el-input>
    </el-form-item>
    <!-- <el-form-item :label="msg('上架码')">
        <el-input v-model="code1"></el-input>
    </el-form-item> -->
    <el-form-item :label="msg('货架号')">
        <el-input v-model="code"></el-input>
    </el-form-item>
    <el-form-item :label="msg('自动保存')">
        <el-switch v-model="openAutoSave " v-if="isAdd" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
    </el-form-item>
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" @click="closeDialog()">{{msg('取消')}}</el-button>
    </div>
    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio src="/assets/media/1.wav" controls="controls" preload ref="wav"></audio>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'StoreEdit',
  data(){
    return{
       code: null,
       pageCode: null,
       prevPageCode: null,
       isAdd: false,
       openAutoSave: false,
       inputing: false
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      },
      scanPageCode:{
          get(){
              return this.pageCode;
          },
          set(val){
              this.prevPageCode = this.pageCode;
              this.pageCode = val;
          }
      }
  },
  props:{
      id:null,
      shelf:Object,
  },
  mounted: function(){
        var _this = this;
       
  },
  methods:{
    initForm(code, hasAdd){
        this.pageCode = null,
        this.isAdd = hasAdd;
    },
    onSubmit(){
        var url = this.$kit.api.twStore.uppers;

        if(!this.code){
            this.$message("货架号不能为空");
            return;
        }

        if(this.id){
            if(!this.shelf.expPageCode){
                this.$message("上架码不能为空");
                return;
            }
        }else{
            this.shelf.expPageCode = this.pageCode;
        }

        this.shelf.shelfCode = this.code;

        var _this = this;
        this.$axios.post(url, this.shelf)
            .then(function(result){
                if(result.status){
                    _this.$message(result.data.result || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')))
                    _this.closeDialog(true);
                }else{
                    _this.$message(result.data.result || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            }).catch(function (error) {
                console.error(error);
                this.clearInputer();
            });

    },
    closeDialog(type){
        // if(!this.isAdd) 
        //     this.code = null;
        this.$emit("closeDg", true);
    },
    autoSave(){
        
        if(this.inputing == false){
            this.inputing = true;
            var _this = this;
            var timer = setInterval(() => {
                
                if(_this.prevPageCode == _this.pageCode){
                    clearInterval(timer);
                    _this.doAutoSave();
                    _this.inputing = false;


                }else{
                    _this.prevPageCode = _this.pageCode;
                }

            }, 500);
        }
    },
    doAutoSave(){

        if(!this.pageCode){
            this.$message("上架码不能为空");
        }

        if(!this.code){
            this.$message("货架号不能为空");
        }

        var expShelf = {
            shelfCode: this.code,
            expPageCode: this.pageCode
        }

        this.$axios.post(this.$kit.api.twStore.uppers, expShelf)
            .then((result)=>{
                this.$message(result.data.result);
                this.pageCode = null;
                this.prevPageCode = null;

                if(result.data.result.indexOf('上架池') >-1 ){
                    this.$refs.wav.play();  
                }

            }).catch(function (error) {
                console.error(error);
                this.pageCode = null;
                this.prevPageCode = null;
            });


    }
  },
  
}
</script>

<style class="scss" scoped>


</style>
